import { default as ai_45form_45builderhQ6gGQBOJmMeta } from "/var/www/TForm/client/pages/ai-form-builder.vue?macro=true";
import { default as editlFmwRMLdh1Meta } from "/var/www/TForm/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as index9OFkCbI3ZEMeta } from "/var/www/TForm/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexqNt2nrqFlEMeta } from "/var/www/TForm/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexQJxlB9PwXbMeta } from "/var/www/TForm/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareTXGX6VAp8GMeta } from "/var/www/TForm/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statscELqnGJ5PeMeta } from "/var/www/TForm/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissions2AX70oyLBSMeta } from "/var/www/TForm/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showw5sk07sixOMeta } from "/var/www/TForm/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestwq8dvttthVMeta } from "/var/www/TForm/client/pages/forms/create/guest.vue?macro=true";
import { default as indexNuqjrZw6rmMeta } from "/var/www/TForm/client/pages/forms/create/index.vue?macro=true";
import { default as homeceZQHvwXFtMeta } from "/var/www/TForm/client/pages/home.vue?macro=true";
import { default as indexydEZMzNZXmMeta } from "/var/www/TForm/client/pages/index.vue?macro=true";
import { default as loginoIMl5BPHsIMeta } from "/var/www/TForm/client/pages/login.vue?macro=true";
import { default as callbackdC43CNo7tcMeta } from "/var/www/TForm/client/pages/oauth/callback.vue?macro=true";
import { default as emaily5Xn78rRPlMeta } from "/var/www/TForm/client/pages/password/email.vue?macro=true";
import { default as _91token_93wxOFoSgi9HMeta } from "/var/www/TForm/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingk8tZZe4WAUMeta } from "/var/www/TForm/client/pages/pricing.vue?macro=true";
import { default as privacy_45policygAmbuM2dOLMeta } from "/var/www/TForm/client/pages/privacy-policy.vue?macro=true";
import { default as registerq7tCNCrp1AMeta } from "/var/www/TForm/client/pages/register.vue?macro=true";
import { default as access_45tokens823cvqmtwAMeta } from "/var/www/TForm/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountwyklKK2IkqMeta } from "/var/www/TForm/client/pages/settings/account.vue?macro=true";
import { default as adminVhWLrohkcdMeta } from "/var/www/TForm/client/pages/settings/admin.vue?macro=true";
import { default as billingvMhHcAD2dIMeta } from "/var/www/TForm/client/pages/settings/billing.vue?macro=true";
import { default as connectionscdB00DpiHNMeta } from "/var/www/TForm/client/pages/settings/connections.vue?macro=true";
import { default as indexX2ik7Jm1IdMeta } from "/var/www/TForm/client/pages/settings/index.vue?macro=true";
import { default as passwordLQKDYwhIkwMeta } from "/var/www/TForm/client/pages/settings/password.vue?macro=true";
import { default as profilesgiHnTBr5pMeta } from "/var/www/TForm/client/pages/settings/profile.vue?macro=true";
import { default as workspaceJvr4JnOk3PMeta } from "/var/www/TForm/client/pages/settings/workspace.vue?macro=true";
import { default as settingsaUPxjBc0X6Meta } from "/var/www/TForm/client/pages/settings.vue?macro=true";
import { default as errorRSR4fQtxRwMeta } from "/var/www/TForm/client/pages/subscriptions/error.vue?macro=true";
import { default as successqGIu6vYSRhMeta } from "/var/www/TForm/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93IHeTc4e0RmMeta } from "/var/www/TForm/client/pages/templates/[slug].vue?macro=true";
import { default as indexo05csbto6TMeta } from "/var/www/TForm/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93R1Ml37UqMmMeta } from "/var/www/TForm/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesBlQON80ObyMeta } from "/var/www/TForm/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93o8kuy0RdCYMeta } from "/var/www/TForm/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsc7Q25T8FTRMeta } from "/var/www/TForm/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentialslZVNsrIHAXMeta } from "/var/www/TForm/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builderhQ6gGQBOJmMeta || {},
    component: () => import("/var/www/TForm/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editlFmwRMLdh1Meta || {},
    component: () => import("/var/www/TForm/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/var/www/TForm/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showw5sk07sixOMeta?.name,
    path: "/forms/:slug()/show",
    meta: showw5sk07sixOMeta || {},
    component: () => import("/var/www/TForm/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    redirect: indexqNt2nrqFlEMeta?.redirect,
    component: () => import("/var/www/TForm/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexQJxlB9PwXbMeta || {},
    component: () => import("/var/www/TForm/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: shareTXGX6VAp8GMeta || {},
    component: () => import("/var/www/TForm/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: statscELqnGJ5PeMeta || {},
    component: () => import("/var/www/TForm/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissions2AX70oyLBSMeta || {},
    component: () => import("/var/www/TForm/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guestwq8dvttthVMeta || {},
    component: () => import("/var/www/TForm/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexNuqjrZw6rmMeta || {},
    component: () => import("/var/www/TForm/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homeceZQHvwXFtMeta || {},
    component: () => import("/var/www/TForm/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/TForm/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginoIMl5BPHsIMeta || {},
    component: () => import("/var/www/TForm/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/var/www/TForm/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: emaily5Xn78rRPlMeta || {},
    component: () => import("/var/www/TForm/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93wxOFoSgi9HMeta || {},
    component: () => import("/var/www/TForm/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingk8tZZe4WAUMeta || {},
    component: () => import("/var/www/TForm/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policygAmbuM2dOLMeta || {},
    component: () => import("/var/www/TForm/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerq7tCNCrp1AMeta || {},
    component: () => import("/var/www/TForm/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsaUPxjBc0X6Meta?.name,
    path: "/settings",
    meta: settingsaUPxjBc0X6Meta || {},
    component: () => import("/var/www/TForm/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/var/www/TForm/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountwyklKK2IkqMeta || {},
    component: () => import("/var/www/TForm/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminVhWLrohkcdMeta || {},
    component: () => import("/var/www/TForm/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingvMhHcAD2dIMeta || {},
    component: () => import("/var/www/TForm/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionscdB00DpiHNMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/var/www/TForm/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexX2ik7Jm1IdMeta?.redirect,
    component: () => import("/var/www/TForm/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordLQKDYwhIkwMeta || {},
    component: () => import("/var/www/TForm/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profilesgiHnTBr5pMeta || {},
    component: () => import("/var/www/TForm/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspaceJvr4JnOk3PMeta || {},
    component: () => import("/var/www/TForm/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: errorRSR4fQtxRwMeta || {},
    component: () => import("/var/www/TForm/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successqGIu6vYSRhMeta || {},
    component: () => import("/var/www/TForm/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/var/www/TForm/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/var/www/TForm/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/var/www/TForm/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatesBlQON80ObyMeta || {},
    component: () => import("/var/www/TForm/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/var/www/TForm/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsc7Q25T8FTRMeta || {},
    component: () => import("/var/www/TForm/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/var/www/TForm/client/pages/update-credentials.vue").then(m => m.default || m)
  }
]